import React from "react";
import '../styles/shortForm.css';
const short1Page = () => (
    <div class="grid-container">
    <div class="left_space"></div>
    <div class="short1-main">
    <div class="long2-main1 shortpage-bg-image">
        <div className="logo-div">
                <a aria-current="page" className="logo" href="/"><div className="logo-image"></div>
                 </a></div>
                 <div className="nav-belt register-header-section">
                     <div className="wp-block-column grid-column-parent">
                         <h3 className="register-header-title">Create Your MyTEXpress profile</h3>
                         <p className="register-header-text">Sign up to be the first to know about exclusive discounts and offers on the LBJ, NTE &amp; NTE 35W TEXpress Lanes.</p>
                     </div>
                     <div className="wp-block-column grid-column-children long2-form-section">
                        <div id='lightbox-inline-form-6fe78ee2-5087-4af7-97ac-305857418869' class="register-formBorder"></div>
                     </div>
                 </div>
        </div>
        <div class="long2-main2">
            <h3 className="form-section-header register-form-section-header">MyTEXpress Member Benefits</h3>
                    <div className="register-short-bot">
                        <div className="short-bot-left">
                            <div className="register-vector-img"></div>
                            <p className="register-form-section-title">Offers and Discounts</p>
                            <p className="register-form-section-text">Determine your eligibility to save time and money on the LBJ, NTE &amp; NTE 35W TEXpress Lanes.</p>
                        </div>
                        <div className="short-bot-right">
                            <div className="register-alerts-img"></div>
                            <h3 className="register-form-section-title">Updates and Traffic Alerts</h3>
                            <p className="register-form-section-text">Make sure you're the first to know about any construction updates or traffic alerts along your TEXpress Lanes route.</p>
                        </div>
                    </div>
                <a href='https://www.texpresslanes.com/faq-page' target='_blank'><p className="faq">MyTEXpress FAQs</p></a>
            </div>
    </div>  
    <div class="right_space"></div>
    <div class="footer">
            <div class="register-footer1"><a href="https://www.facebook.com/TEXpressLanes" target="_blank"><img class="fb-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> | <a href="https://twitter.com/texpresslanes" target="_blank"><img class="twitter-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> |  <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">Privacy Policy</a> │ ©2020 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved</div>
        </div>
</div>
);

export default short1Page;
